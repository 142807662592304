import { HttpClient } from "@angular/common/http";
import 'rxjs/add/operator/toPromise';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';


@Injectable(
  {
    providedIn: 'root'
  }
)
export class DownloadApkService
{
  constructor(private http: HttpClient) { }
  private baseUrl = `${environment.apiUrl}/fams/downloadAPK`;

//   createDoctype(doctype: Object): Observable<Object> {
//     return this.http.post(`${this.baseUrl}` + `/dow`, doctype);
//   }

downloadAPKFile() {
    return this.http.get(`${this.baseUrl}/downloadAPKFile/`, { responseType: 'blob' })
  }
 


  allDoctype(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }

}