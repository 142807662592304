import { Component, OnInit } from '@angular/core';
import { EmployeeDataService } from '../services/employeedataService';
import { MovementService } from '../services/movementService';
import { ErrorCode } from '../Globle';
import { dataBound } from '@syncfusion/ej2-spreadsheet';

@Component({
  selector: 'asset-verification',
  templateUrl: './asset-verification.component.html',
  styleUrls: ['./asset-verification.component.scss']
})
export class AssetVerificationComponent implements OnInit {

  empCodeArr

  // assetArr =[{
  //   empCode : 123
  // }]
  assetArr
  constructor(  private movementService : MovementService) { }

  ngOnInit(): void {
  }
 
  searchAssets(){
    var companyCode=(<HTMLInputElement>document.getElementById("companyCode")).value;
    var empCode=(<HTMLInputElement>document.getElementById("empCode")).value;
    this.movementService.searchMovementAssets(companyCode,empCode).subscribe(
      data =>{
        if(data.ok || !data.ok){
          this.assetArr = data.body;
         // console.log("this.assetArr ",this.assetArr);
          // ErrorCode.getErrorCode(data)
        }
      
      },error=>{
        if(error.ok || !error.ok){
          ErrorCode.getErrorCode(error)
        }
      }
    )
  }
  
  checkFlag(i){
    var flag=(<HTMLInputElement>document.getElementById("flag"+i)).value;
    if(flag === "No"){
      (<HTMLInputElement>document.getElementById("remark"+i)).readOnly = false;
      (<HTMLInputElement>document.getElementById("remark"+i)).autofocus = true;
    }
  }
  update(){
    if(this.assetArr.length >0){
      for(let i=0;i< this.assetArr.length;i++){
        this.assetArr[i].flag = (<HTMLInputElement>document.getElementById("flag"+i)).value;
        this.assetArr[i].remark = (<HTMLInputElement>document.getElementById("remark"+i)).value;
      }
     // console.log("this.assetArr ",this.assetArr);
      this.movementService.createAssetVerification(this.assetArr).subscribe(
        resp =>{
          let response : any = resp
          if(response.ok || !response.ok){
            ErrorCode.getErrorCode(response)
          }
        },error=>{
          if(error.ok || !error.ok){
            ErrorCode.getErrorCode(error)
          }
        }
        )
        this.assetArr =[];
        (<HTMLInputElement>document.getElementById("companyCode")).value = '';
        (<HTMLInputElement>document.getElementById("empCode")).value = '';

    }else{
      sweetAlert("Invalid Operation!!")
    }
  }
}
