<section class="login__container">
    <section class="row">
        <section class="login__form">
            <div class="login__heading">
                <!-- <img src="../../assets/images/newLogo.png" alt=""  class="logo"  />  -->
            </div>
            <div class="login__subheading mb-5">Ledgers24<BR></div>

            <div class="col-md-12 text-center">
                <p class="sub-title">Please Log in with your Username<br>
                    <!-- <p class="sub-title">V(1.1)</p> -->
                    <span style="width:20px;height: 20px;text-align: center;">Version(9.0)</span>
                </p>
            </div>
            <!-- <div style="text-align: center;">
                <span style="width:20px;height: 20px;text-align: center;">V(1.1)</span>
            </div> -->
            <!-- (ngSubmit)="userLogin()" -->
            <form class="col-md-12" [formGroup]="loginForm">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" formControlName="companyId" placeholder="Company ID"
                        aria-label="Email" aria-describedby="Email" autofocus
                        [ngClass]="{ 'is-invalid': loginCheck && f.companyId.errors }"> <!--(blur)="getCompany()"-->
                    <div *ngIf="loginCheck && f.companyId.errors" class="invalid-feedback">
                        <div *ngIf="f.companyId.errors.required">Company ID is required</div>
                    </div>
                </div>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" formControlName="user" placeholder="Login ID"
                        aria-label="Email" aria-describedby="Email" autofocus
                        [ngClass]="{ 'is-invalid': loginCheck && f.user.errors }"> <!-- (blur)="getLoginId()"-->
                    <div *ngIf="loginCheck && f.user.errors" class="invalid-feedback">
                        <div *ngIf="f.user.errors.required">Login ID is required</div>
                    </div>
                </div>

                <div class="input-group mb-3">
                    <input type="password" class="form-control" formControlName="password" placeholder="Password"
                        aria-label="Password" aria-describedby="Password"
                        [ngClass]="{ 'is-invalid': loginCheck && f.password.errors }">
                    <div *ngIf="loginCheck && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>

                <!-- <div class="text-right mb-3"> -->

                <!-- <div class="input-group mb-6"> -->
                <div class="form-group row">
                    <!-- <button type="submit" class="btn btn-primary cursor-pointer" (click)="userLogin()">Login</button>
                    <button class="btn btn-primary cursor-pointer" (click)="forgotPwd()">Forgot</button>
                    <button (click)="openRegistration()" class="btn btn-secondary cursor-pointer">On
                        Boarding</button> -->

                    <div class="col-sm-3 col-md-3">
                        <button type="submit" class="btn btn-primary cursor-pointer"
                            (click)="userLogin()">Login</button>
                    </div>
                    <div class="col-sm-3 col-md-3">
                        <button class="btn btn-primary cursor-pointer" (click)="forgotPwd()">Forgot</button>
                    </div>
                    <div class="col-sm-6 col-md-6">
                        <button (click)="openRegistration()" class="btn btn-secondary cursor-pointer">On
                            Boarding</button>
                    </div>

                </div>
                <!-- </div> -->
            </form>
            <!-- div class="font-weight-light font-italic text-center mt-5 pt-4">Best suited for Microsoft Edge and
            Firefox 54.x. Also available for the browser of your smartphone / tablet.</div -->
        </section>
        <!-- <span class="created-by">
            © 2020, Powered by A F Solutions
            </span> Test ERP With FA V 4.1.2 -->
    </section>
</section>